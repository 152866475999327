* { margin:0; padding: 0; }
*, *::before, *::after { box-sizing: border-box; }

body { 
  min-height: 100dvh;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input, button, textarea, select { font:inherit }

p { text-wrap: pretty; } 
a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 { text-wrap: balance; }

img, video, svg { height:auto; max-width:100%; }

@media (prefers-reduced-motion: reduce) {
  *, *::before, *::after { 
    animation-duration: 0,01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0,01ms !important;
    scroll-behavior: auto !important;
    transition: none
  }
}